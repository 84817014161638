@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

@mixin type-1
{
	font-family: Roboto, sans-serif;
	line-height: 1.4;
}

@mixin type-2
{
	font-family: Roboto, sans-serif;
	font-weight: 300;
	font-size: 1.75rem;
}

@mixin type-3
{
	@include type-2;
}

@mixin type-4
{
	@include type-2;
}

@mixin type-5
{
	font-family: Roboto, sans-serif;
	font-weight: 700;
	font-size: 1.1rem;
	letter-spacing: .07em;
}

@mixin type-6
{
	font-family: Roboto, sans-serif;
	font-weight: 500;
	font-size: .75rem;
	text-transform: uppercase;
	letter-spacing: .01em;
	line-height: 1;
}

@mixin type-7
{
	font-family: Roboto, sans-serif;
	font-weight: 300;
	font-size: .85rem;
	letter-spacing: .05em;
	line-height: 1.4;
}

@mixin type-8
{
	font-family: Roboto, sans-serif;
	font-weight: 500;
	font-size: 1rem;
	letter-spacing: .03em;
}

@mixin type-9
{
	font-family: Roboto, sans-serif;
	font-weight: 300;
	font-size: .75rem;
}

@mixin type-10
{
	font-family: Roboto, sans-serif;
	font-weight: 500;
	font-size: .95rem;
	letter-spacing: .03em;
	//text-transform: uppercase;
}

@mixin type-11
{
	font-family: Roboto, sans-serif;
	font-weight: 500;
	font-size: 2.25rem;
}