@include block(notification)
{
	position: fixed;
	z-index: 9999;
	bottom: $rule;
	left: 50%;
	border-radius: $border-radius-01;
	background-color: rgba($color02, .9);
	color: $color01;
	padding: $rule / 3 $rule * 2;
	animation: notification-in .25s both;
	min-width: $rule * 8;
	text-align: center;
	white-space: nowrap;
	@include type-6;

	@include modifier(hide)
	{
		animation: notification-out .25s both;
	}

	@include modifier(success)
	{
		background-color: rgba($color09, .95);
	}

	@include modifier(error)
	{
		background-color: rgba($color11, .95);
	}
}

@keyframes notification-in {

	from {
		opacity: 0;
		transform: translateX(-50%) translateY($rule);
	}

	to {
		opacity: 1;
		transform: translateX(-50%) translateY(0);
	}
}

@keyframes notification-out {

	from {
		opacity: 1;
		transform: translateX(-50%) translateY(0);
	}

	to {
		opacity: 0;
		transform: translateX(-50%) translateY($rule);
		pointer-events: none;
	}
}