@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block(list-item)
{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax($rule, 1fr));
	align-items: center;
	padding: $rule / 2.25 $rule / 1.5;
	color: $color06;

	@include element(column)
	{
		flex: 6;
		align-self: center;
		padding-left: $rule / 1.25;
		margin-right: $rule / 1.25;
		border-left: 1px solid $color07;

		&:first-child
		{
			padding-left: 0;
			border-left: none;
		}

		&:last-child { margin-right: 0; }

		@include modifier(highlight)
		{
			@include type-8;
		}

		@for $i from 2 through 15
		{
			@include modifier(span-#{$i})
			{
				grid-column: span #{$i};
			}
		}

		@include modifier(clean)
		{
			border: none;
		}

		@include modifier(valign-top)
		{
			align-self: inherit;
		}

		@include modifier(no-wrap)
		{
			white-space: nowrap;
		}
	}

	@include element(column-list)
	{
		li
		{
			margin-bottom: $rule / 4;
			line-height: 1.25;

			&:last-child
			{
				margin-bottom: 0;
			}
		}
	}

	@include element(price)
	{
		@include modifier(sale)
		{
			text-decoration: line-through;
			padding-right: $rule / 4;
		}
	}

	@include element(header)
	{
		display: flex;
		justify-content: space-between;
	}

	@include element(thumb)
	{
		display: flex;
		justify-content: center;
		align-items: center;
		width: $rule * 2;
		height: $rule * 2;
		border-radius: 50%;
		overflow: hidden;
		text-transform: uppercase;
		background-color: $color05;

		img
		{
			display: block;
			width: 100%;
		}
	}

	@include element(title)
	{
		display: block;
		@include type-8;
		color: $color06;
		margin-bottom: $rule / 4;
	}

	@include element(subtitle)
	{
		font-size: .75rem;
	}

	@include element(vertical-stack)
	{
		display: inline-block;

		> *
		{
			margin: $rule / 10 0;

			&:first-child { margin-top: 0; }
			&:last-child { margin-bottom: 0; }
		}
	}

	@include element(link)
	{
		border-bottom: 1px solid $color07;
	}
}