@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block(module-nav)
{
	width: 100%;

	@include element(list)
	{
		// for reference
	}

	@include element(item)
	{
		position: relative;
		margin: $rule / 1.5 0;

		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }

		&:before
		{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: $border-width-03;
			background-color: $color03;
			transform: translateX(-100%);
			transition: transform .25s;
		}

		@include modifier('active')
		{
			&:before
			{
				transform: translateX(0);
			}

			svg
			{
				fill: $color03;
			}

			@include element(item-title)
			{
				color: $color03;
			}
		}
	}

	@include element(item-button)
	{
		width: 100%;
		padding: $rule / 3 $rule / 1.25;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		svg
		{
			transition: fill .5s;
		}

		&:hover
		{
			svg
			{
				fill: $color03;
			}

			@include element(item-title)
			{
				color: $color03;
			}
		}
	}

	@include element(item-title)
	{
		@include type-6;
		margin-top: $rule / 4;
		color: $color06;
		transition: color .5s;
	}
}