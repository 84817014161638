@import 'style/config';
@import 'style/typography';
@import 'style/bem';

@keyframes app-initialization
{
	from
	{
		opacity: 0;
	}
}

@include block('app')
{
	display: flex;
	flex-direction: column;
	animation: app-initialization .75s;
}

@include block('app-note')
{
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	right: 0;
	height: 4vh;
	background-color: deeppink;
	color: white;
	font-weight: 600;
}

@include block('app-loader')
{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $color01;
	display: flex;
	justify-content: center;
	align-items: center;
}

@include block('content-loader')
{
	display: flex;
	justify-content: center;
	align-items: center;
}