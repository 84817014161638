// colors

$color01: #ffffff;
$color02: #1e232d;
$color03: #d8232a;
$color04: #aaaaaa;
$color05: #f3f3f3;
$color06: #717171;
$color07: #e6eded;
$color08: #bac0c0;
$color09: #81af53;
$color10: #d5b531;
$color11: #b02c2b;
$color12: #6888c7;
$color13: #7d2e89;
$color14: #004D92;
$color15: #52bc8f;
$color16: #ffde00;
$color17: lighten($color02, 20%);

// breakpoints
$breakpoints: (
	650px,
	750px,
	1024px,
	1150px,
	1280px,
	1375px,
	1500px,
	1600px,
	1750px,
	1900px,
);

// distances, lengths, margins
$rule: 2rem;

$border-radius-01: 3px;
$border-radius-02: $rule;
$border-radius-03: $rule / 3;

$border-width-01: 1px;
$border-width-02: 2px;
$border-width-03: 5px;
$border-width-04: 3px;

// shadows
$box-shadow-01: 1px 2px 7px -3px rgba($color02, .1);
$box-shadow-02: 1px 2px 35px 0 rgba($color02, .15);
$box-shadow-03: 1px 3px 5px -1px rgba(white, .5);
$box-shadow-04: 0 0 50px 0 rgba($color02, .25);
$box-shadow-05: 0 0 75px 0 rgba($color02, .4);

// easings
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);